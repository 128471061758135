:root {
	--input-border-radius: 4px;
	--button-border-radius: 4px;
}

a {
	text-decoration: none;
	color: #dac100;
}

h1,
h2,
h3,
h4 {
	font-family: 'Poppins';
}

.primaryBtn {
	cursor: pointer;
	padding: 0.45rem 0.85rem;
	background-color: transparent;
	border: 1px solid #eac500;
	font-weight: 600;
	border-radius: var(--button-border-radius);
	white-space: nowrap;
	font-family: 'Poppins';
	color: #eac500;
	transition: all 100ms ease-in;
}

.primaryBtn p,
.secondaryBtn p {
	margin-block-start: 0;
	margin-block-end: 0;
}

.primaryBtn:hover {
	background-color: #222;
	color: #edc500;
}

.primaryBtn[disabled] {
	opacity: 0.6;
	/* Make the button appear faded */
	cursor: not-allowed;
	/* Change the cursor to indicate it's not clickable */
	pointer-events: none;
	/* Ensure the button is not interactive */
	background-color: #3d3c3c;
	/* Optional: Change the background color to a grey shade*/
	/* border-color: #666;  */
}

.secondaryBtn[disabled] {
	opacity: 0.6;
	/* Make the button appear faded */
	cursor: not-allowed;
	/* Change the cursor to indicate it's not clickable */
	pointer-events: none;
	/* Ensure the button is not interactive */
	background-color: #3d3c3c;
	/* Optional: Change the background color to a grey shade*/
	/* border-color: #666;  */
}

.secondaryBtn {
	cursor: pointer;
	padding: 0.45rem 0.85rem;
	color: #edc500;
	background-color: #1a1a1a;
	border: none;
	font-weight: 600;
	border-radius: var(--button-border-radius);
	white-space: nowrap;
	font-family: 'Poppins';
	transition: all 100ms ease-in;
}

.secondaryBtn:hover {
	background-color: #222;
}

.loaderContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  

.clientJobsContainer .bgBlob1 {
	position: fixed;
	height: 300px;
	width: 300px;
	background-color: #444;
	border-radius: 50%;
	rotate: -45deg;
	top: 200px;
	left: 400px;
}

.clientJobsContainer .bgBlob2 {
	position: fixed;
	height: 150px;
	width: 100px;
	background-color: rgb(237, 197, 0, 0.5);
	border-radius: 50%;
	rotate: -45deg;
	bottom: 200px;
	right: 150px;
}

.clientJobsContainer .bgBlob3 {
	position: fixed;
	height: 200px;
	width: 250px;
	background-color: #444;
	border-radius: 50%;
	rotate: -35deg;
	bottom: 100px;
	right: 250px;
}

.clientJobsContainer {
	display: flex;
	flex-direction: column;
	background-color: #111;
}

.clientJobsContainer .navbar {
	padding: 2rem;
	display: flex;
	justify-content: space-between;
}

.clientJobsContainer .clientJobsMain {
	min-height: 90vh;
	display: flex;
}

.clientJobsMain .jobSidebar {
	z-index: 0;
	min-height: 100%;
	min-width: 20vw;
	background-color: rgb(21, 21, 21, 0.65);
	padding: 1rem;
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	border-top: 2px solid #222;
	backdrop-filter: blur(40px);
}

.jobSidebar .jobSidebarTab {
	display: flex;
	width: 100%;
}

.jobSidebar button {
	width: 100%;
	padding: 0 1.25rem;
	display: flex;
	align-items: center;
	color: #fff;
	background: transparent;
	border: none;
	border-radius: 0.4rem;
	font-family: 'Poppins';
	font-size: 0.9rem;
	transition: all 200ms ease-in;
}

.jobSidebar button:hover {
	background: #181818;
	color: #eac100;
	cursor: pointer;
}

.jobSidebar button.active {
	background: #1a1a1a;
	color: #eac100;
}


.clientJobsMain .createJobContent {
	z-index: 10;
	min-height: 100%;
	width: 100%;
	background-color: rgb(21, 21, 21, 0.75);
	padding: 1rem 2rem;
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	box-shadow: 0 -10px 20px 5px rgba(0, 0, 0, 0.25);
	border-top: 2px solid #333;
	backdrop-filter: blur(40px);
}

.createJobContent .jobsListContainer {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.createJobContent .jobsListContainer .jobsList {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.jobsListContainer .jobsList .jobCard {
	cursor: pointer;
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;
	padding: 0 1.5rem;
	border: 1px solid #333;
	border-radius: 4px;
	font-family: 'Poppins';
	transition: all 200ms ease-in;
  }
  
  .jobsListContainer .jobsList .jobCard:hover {
	border-color: #eac100;
  }
  
  .jobsListContainer .jobsList .jobCard:hover p {
	color: #eac100;
  }

  .jobCard .statusTag {
	width: fit-content;
	padding: 0.25rem 0.5rem;
	background-color: #282828;
	color: #eac100;
	border-radius: 50vw;
	font-size: 0.7rem;
  }
