/* Specificity added for create-job container */

a {
	text-decoration: none;
	color: #dac100;
  }
  
  h1,
  h2,
  h3,
  h4 {
	font-family: 'Poppins';
  }
  
  button.primary {
	cursor: pointer;
	padding: 0.65rem 1rem;
	background-color: #edc500;
	border: none;
	font-weight: 600;
	border-radius: 10px;
	white-space: nowrap;
	font-family: 'Poppins';
	transition: all 100ms ease-in;
  }
  
  button.primary:hover {
	background-color: #222;
	color: #edc500;
  }
  
  button.secondary {
	cursor: pointer;
	padding: 0.65rem 1rem;
	color: #edc500;
	background-color: transparent;
	border: none;
	font-weight: 600;
	border-radius: 10px;
	white-space: nowrap;
	font-family: 'Poppins';
	transition: all 100ms ease-in;
  }
  
  button.secondary:hover {
	background-color: #222;
  }
  
  .jobInterviewContainer .bgBlob1 {
	position: fixed;
	height: 300px;
	width: 300px;
	background-color: #444;
	border-radius: 50%;
	rotate: -45deg;
	top: 200px;
	left: 300px;
  }
  
  .jobInterviewContainer .bgBlob2 {
	position: fixed;
	height: 150px;
	width: 100px;
	background-color: rgb(237, 197, 0, 0.5);
	border-radius: 50%;
	rotate: -45deg;
	bottom: 200px;
	right: 150px;
  }
  
  .jobInterviewContainer .bgBlob3 {
	position: fixed;
	height: 200px;
	width: 250px;
	background-color: #444;
	border-radius: 50%;
	rotate: -35deg;
	bottom: 100px;
	right: 250px;
  }
  
  .jobInterviewContainer {
	display: flex;
	flex-direction: column;
	background-color: #111;
  }
  
  .jobInterviewContainer .navbar {
	padding: 2rem;
	display: flex;
	justify-content: space-between;
  }
  
  .jobInterviewContainer .jobInterviewMain {
	min-height: 90vh;
	display: flex;
  }
  
  .jobInterviewMain .interviewSidebar {
	z-index: 0;
	min-height: 100%;
	min-width: 20vw;
	background-color: rgb(21, 21, 21, 0.65);
	padding: 1rem;
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	border-top: 2px solid #222;
	backdrop-filter: blur(40px);
  }
  
  .interviewSidebar .interviewSidebarTab {
	display: flex;
	width: 100%;
  }
  
  .interviewSidebar button {
	width: 100%;
	padding: 0 1.25rem;
	display: flex;
	align-items: center;
	color: #fff;
	background: transparent;
	border: none;
	border-radius: 0.4rem;
	font-family: 'Poppins';
	font-size: 0.9rem;
	transition: all 200ms ease-in;
  }
  
  .interviewSidebar button:hover {
	background: #181818;
	color: #eac100;
	cursor: pointer;
  }
  
  .interviewSidebar button.active {
	background: #1a1a1a;
	color: #eac100;
  }
  
  .searchContainer {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-bottom: 1.5rem;
  }
  
  .searchBar {
	display: flex;
	gap: 1rem;
  }
  
  .searchInput {
	padding: 0.5rem 1rem;
	border: 1px solid #333;
	border-radius: 4px;
	background-color: #181818;
	color: #fff;
	font-family: 'Poppins';
	font-size: 1rem;
	width: 100%;
	transition: all 200ms ease-in;
  }
  
  .searchInput:focus {
	border-color: #eac100;
	outline: none;
  }
  
  .jobInterviewContent .interviewsListContainer {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
  }
  
  .jobInterviewContent .interviewsListContainer .interviewsList {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
  }
  
  .disabledCard {
	opacity: 0.6;
	pointer-events: none;
  }
  
  .addToJobButton:disabled {
	/* background-color: #ccc; */
	cursor: not-allowed;
  }
  
  .confirmationMessage {
	background-color: #4caf50;
	color: white;
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 5px;
	font-weight: bold;
  }
  
  
  
  .interviewsListContainer .interviewsList .interviewCard {
	cursor: pointer;
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;
	padding: 0 1.5rem;
	border: 1px solid #333;
	border-radius: 4px;
	font-family: 'Poppins';
	transition: all 200ms ease-in;
  }
  
  .interviewsListContainer .interviewsList .interviewCard:hover {
	border-color: #eac100;
  }
  
  .interviewsListContainer .interviewsList .interviewCard:hover p {
	color: #eac100;
  }
  
  .jobsList {
	display: flex;
	flex-direction: column;
	gap: 1rem;
  }
  
  .jobCard {
	padding: 1rem;
	border: 1px solid #333;
	border-radius: 8px;
	background-color: #181818;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	transition: all 200ms ease-in;
  }
  
  .jobCard:hover {
	border-color: #eac100;
  }
  
  .jobCard h4 {
	font-size: 1.2rem;
	margin-bottom: 0.5rem;
	color: #eac100;
  }
  
  .jobCard p {
	font-size: 1rem;
	color: #aaa;
	margin: 0;
  }
  
  .interviewsSubList {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	margin-top: 1rem;
  }
  
  .interviewsSubList .interviewCard {
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 1rem;
	border: 1px solid #444;
	border-radius: 4px;
	transition: all 200ms ease-in;
	background-color: #202020;
  }
  
  .interviewsSubList .interviewCard:hover {
	border-color: #eac100;
	background-color: #292929;
  }
  
  .interviewsSubList .interviewCard p {
	margin: 0;
	color: #fff;
	font-size: 0.9rem;
  }
  
  .interviewsSubList .interviewCard p.statusTag {
	font-size: 0.8rem;
	padding: 0.25rem 0.5rem;
	border-radius: 50px;
	background-color: #333;
	color: #eac100;
	text-transform: uppercase;
  }
  
  .interviewCard .statusTag {
	width: fit-content;
	padding: 0.25rem 0.5rem;
	background-color: #282828;
	color: #eac100;
	border-radius: 50vw;
	font-size: 0.7rem;
  }
  
  .jobInterviewMain .jobInterviewContent {
	z-index: 10;
	min-height: 100%;
	width: 100%;
	background-color: rgb(21, 21, 21, 0.75);
	padding: 1rem 2rem;
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	box-shadow: 0 -10px 20px 5px rgba(0, 0, 0, 0.25);
	border-top: 2px solid #333;
	backdrop-filter: blur(40px);
  }
  
  .create-interviews-container .loader-container {
	z-index: 100;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.9);
  }
  
  .loader {
	border: 4px solid #f3f3f3;
	border-top: 4px solid #edc500;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;
  }
  
  .create-interviews-container .loader-container .loader-message {
	color: #aaa;
  }
  
  /* Style for the interview card */
  .interviewCard {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	padding: 0 1.5rem;
	border: 1px solid #333;
	border-radius: 4px;
	font-family: 'Poppins';
	transition: all 200ms ease-in;
  }
  
  /* Details section within each interview card */
  .interviewDetails {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
  }
  
  /* Button for adding an interview to a job */
  .addToJobButton {
	padding: 0.5rem 1rem;
	background-color: #222;
	color: #edc500;
	border: none;
	border-radius: 4px;
  
	font-weight: 600;
	font-family: 'Poppins';
	cursor: pointer;
	transition: all 200ms ease-in;
  }
  
  /* Hover effect for the button */
  .addToJobButton:hover {
	background-color: #edc500;
	color: #111;
  }

/* Wrapper for the modal */
.modalWrapper {
	position: fixed;
	top: 0%;
	left: 50%;
	transform: translate(-50%, -50%); /* Center the modal */
	z-index: 1050;                    /* Ensure it's above other content */
	width: 100%;
	height: 100%;                    /* Full viewport height */
	display: flex;
	justify-content: center;
	align-items: center;
  }
  /* Optional: Overlay for the modal */
.modalWrapper::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 0; /* Full viewport height */
	background-color: rgba(0, 0, 0, 0.8);
	z-index: -1; /* Place it behind the modal */
  }
  
  @keyframes color {
	0% {
	  background-position: 0 50%;
	}
	50% {
	  background-position: 100% 50%;
	}
	100% {
	  background-position: 0 50%;
	}
  }
  