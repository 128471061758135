/* Specificity added for create-job container */

button.primary {
	cursor: pointer;
	padding: 0.5rem 1rem;
	background-color: #edc500;
    color: #000;
	border: none;
	font-weight: 600;
	border-radius: 10px;
	white-space: nowrap;
	font-family: 'Poppins';
	transition: all 100ms ease-in;
}

button.primary:hover {
	background-color: #222;
	color: #edc500;
}

button.secondary {
	cursor: pointer;
	padding: 0.65rem 1rem;
	color: #edc500;
	background-color: transparent;
	border: none;
	font-weight: 600;
	border-radius: 10px;
	white-space: nowrap;
	font-family: 'Poppins';
	transition: all 100ms ease-in;
}

button.secondary:hover {
	background-color: #222;
}

.create-evaluation-container {
	display: flex;
	flex-direction: column;
	height: 100vh;
	background: linear-gradient(60deg, #000, #222, #000);
	animation: color 5s ease-in-out infinite;
	overflow: scroll;
}

.loader-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	text-align: center;
}

.loader {
	border: 4px solid #f3f3f3;
	border-top: 4px solid #edc500;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;
}

@keyframes color {
	0% {
		background-position: 0 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0 50%;
	}
}

/* Specificity added for create-evaluation navbar */
.create-evaluation-container .create-evaluation-navbar {
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 30px;
}

.create-evaluation-container .logo {
	font-size: 24px;
	font-weight: bold;
	text-decoration: none;
}

.create-evaluation-container .logo-peh {
	color: #fff;
}

.create-evaluation-container .logo-chaan {
	color: #edc500;
}

/* Specificity added for navbar links */
.create-evaluation-container .nav-link {
	color: white;
	text-decoration: none;
	margin: 0 10px;
	font-size: 18px;
}
.create-evaluation-container .create-evaluation-navbar h2{
	display: flex;
	align-items: flex-end;
	justify-content: right;
	font-size: 18px;

}

.create-evaluation-container .nav-link:hover {
	color: #e8990f;
}

/* Specificity added for create-evaluation main */
.create-evaluation-container .create-evaluation-main {
	flex: 1;
	padding: 20px;
	margin-inline: auto;
	width: 60%;
}

.create-evaluation-container .create-evaluation-main h1 {
	display: flex;
	/* align-items: center; */
	justify-content: center;
	font-size: 40px;
	color: white; /* Ensure text remains visible */
}

/* Specificity added for h2 tags */
.create-evaluation-container .create-evaluation-main h2 {
	color: white;
}

 
.evaluation-form {
    margin-top: 4rem;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
}
  
  
.evaluation-form .form-group {
    position: relative;
    width: 48%;
}

.evaluation-form .form-group.textarea {
    width: 100%;
}
  
.evaluation-form .form-group input,
.evaluation-form .form-group select,
.evaluation-form .form-group textarea {
    box-sizing: border-box;
    width: 100%;
    padding: 0.75rem 1.25rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    color: #fff;
    font-family: 'Poppins';
    background: none;
    font-size: 14px;
    outline: none;
    resize: vertical;
    background-color: transparent;
}

.evaluation-form .form-group select {
background: #222;
}

.evaluation-form input:focus,
.evaluation-form select:focus {
    border-color: #fff623;
    outline: none;
}

.evaluation-form .form-group label {
    position: absolute;
    top: 25px;
    left: 20px;
    transform: translateY(-50%);
    font-size: 16px;
    color: #ccc;
    transition: 0.2s;
    pointer-events: none;
}

.form-group input:focus ~ label,
.form-group input:valid ~ label,
.form-group select:focus ~ label,
.form-group select:valid ~ label {
    top: 0;
    font-size: 12px;
    color: #fff;
    background-color: #222;
}

button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.create-evaluation-main .criteria-pills {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}
  
.create-evaluation-main .criteria-pills .pill {
    cursor: pointer;
    padding: 0.35rem 0.7rem;
    font-size: 0.75rem;
    border-radius: 50vw;
    color: rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.7);
}