.thank-you-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.thank-you-container h1 {
  font-size: 3rem;
  color: #c28753;
}

.thank-you-container p {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.home-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #e8990f;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.home-button:hover {
  background-color: #c28753;
}
