@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");

.cv-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
    width: 100vw;
    margin: 0 auto;
    background: linear-gradient(60deg, #000, #222, #000);
    animation: color 5s ease-in-out infinite;
}

@keyframes color {
    0% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}

.navbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 40px;
}

.logo {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
}

.logo-peh {
    color: #fff;
}

.logo-chaan {
    color: #edc500;
}

.cv-upload-title {
    font-size: 48px;
    margin-bottom: 40px;
    text-align: center;
    color: #fff;
}

.description {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #d1d1d1;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
}

.dropzone {
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    border: 2px dashed #edc500;
    border-radius: 8px;
    background-color: #111;
    color: #fff;
    text-align: center;
}

.drop-text {
    font-size: 18px;
    color: #edc500;
}

.selected-file {
    margin-top: 10px;
    color: #edc500;
}

.upload-button {
    width: 100%;
    padding: 10px;
    margin: 20px 0;
    background: linear-gradient(90deg, #f7df67, #edc500);
    color: #464646;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    font-size: 18px;
}

.upload-button:hover {
    background: linear-gradient(90deg, #ffe78f, #f7c500);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.upload-button-disabled {
    width: 100%;
    padding: 10px;
    margin: 20px 0;
    border: none;
    border-radius: 24px;
    font-size: 18px;
    background: linear-gradient(90deg, #b3b3b3, #999999);
    color: #666;
    cursor: not-allowed;
    opacity: 0.6;
}
.loader {
    border: 4px solid #edc500;
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 768px) {
    .title {
        font-size: 36px;
        margin-bottom: 20px;
    }

    .description {
        font-size: 18px;
    }

    .form {
        width: 80%;
    }

    .upload-button {
        padding: 12px;
        margin: 16px 0;
        font-size: 16px;
    }
}