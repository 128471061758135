@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");
/* body.verify-otp-body {
    font-family: 'Mulish', sans-serif;
    background-color: #000;
    color: #fff;
    margin: 0;
    padding: 0;
  } */

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.logo-peh {
  color: #fff;
}

.logo-chaan {
  color: #edc500;
}

.verify-otp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  width: 100vw;
  margin: 0 auto;
  background: linear-gradient(60deg, #000, #222, #000);
  animation: color 5s ease-in-out infinite;
}

@keyframes color {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.verify-otp-navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 40px;
}

.verify-otp-navbar .logo {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

/* .verify-otp-container h2 {
    font-size: 48px;
    margin-bottom: 40px;
    text-align: center;
}

.verify-otp-container p {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.verify-otp-container p {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
} */

.verify-otp-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.verify-otp-container input {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  background-color: #000;
  color: #fff;
}

.verify-otp-container .btn {
  width: 100%;
  padding: 15px;
  margin: 20px 0;
  background: linear-gradient(90deg, #f7df67, #edc500);
  color: #464646;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  font-size: 18px;
}

.verify-otp-container .loader {
  border: 4px solid #edc500;
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .verify-otp-container h2 {
    font-size: 36px;
    margin-bottom: 20px;
    text-align: center;
  }

  /* .verify-otp-container p {
    font-size: 18px;
    text-align: center;
  }*/

  .verify-otp-container input {
    padding: 12px;
    margin: 8px 0;
    font-size: 16px;
    width: 80%;
  }

  /* .verify-otp-container button {
    padding: 12px;
    margin: 16px 0;
    font-size: 16px;
    width: 80%;
    background: linear-gradient(90deg, #f7df67, #edc500);
  } */

  .verify-circle-large {
    position: absolute;
    top: 10%;
    right: 20%;
    z-index: -1;
  }

  .verify-circle-small {
    position: absolute;
    top: 40%;
    right: 10%;
    z-index: -1;
  }
}
