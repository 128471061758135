@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.job-details-page-container {
  box-sizing: border-box;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  padding: 20px;
  background: linear-gradient(60deg, #000, #111, #000);
}

.job-details-page-container .dashboard-navbar {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 10px;
  gap: 10px;
}

.job-details-page-container .dashboard-navbar .job-listings-nav {
  text-decoration: none;
  color: #999999;
  margin-right: 10px;
}

.job-details-page-container .job-details-page {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0px 60px;
  padding: 10px;
  border-radius: 8px;
  color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.job-details-page .left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 70%;
  margin-left: 10px;
}

.job-details-page .left .tag {
  padding: 10px;
  background-color: #282828;
  border-radius: 5px;
  color: #fff;
  margin-bottom: 20px;
  margin-left: 0px;
}

.job-details-page .right {
  width: 30%;
  background-color: #000;
  padding: 20px;
  border-radius: 20px;
  color: #fff;
  gap: 20px;
}

.job-details-page .right .interview-list {
  background-color: #000;
  color: #fff;
}

.job-details-page .right .interview-list .interview-item {
  color: #aaa;
  margin: 20px 0;
  font-size: 20px;
}

.job-details-page h1 {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 20px;
  text-align: left;
  font-weight: 600;
}

.job-details-page p {
  font-size: 1.1rem;
  color: #fff;
  line-height: 1.6;
  margin-bottom: 30px;
}

.job-details-page-container .job-status {
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 10px;
}

.job-details-page-container .job-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #fff;
  white-space: pre-line;
  margin-bottom: 20px;
  margin-top: 20px;
}

.job-details-page-container .apply-button {
  display: block;
  width: 100%;
  padding: 12px 20px;
  font-size: 1.1rem;
  margin-top: 10px;
  color: #222;
  background-color: #edc500;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.job-details-page-container .apply-later-button {
  display: block;
  width: 100%;
  padding: 12px 20px;
  font-size: 1.1rem;
  margin-top: 10px;
  color: #edc500;
  background-color: #222;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.job-details-page-container .apply-button:hover {
  background-color: #ffc500;
}

.job-details-page-container .disclaimer-text {
  margin-top: 1rem;
  font-size: 0.8rem;
  color: #edc500; /* Adjust as per design */
  line-height: 1.5;
}

@media (max-width: 768px) {
  .job-details-page-container .job-details-page {
    flex-direction: column;
    margin: 0 20px;
  }

  .job-details-page .left,
  .job-details-page .right {
    width: 100%;
    margin: 0;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .job-details-page h1 {
    font-size: 1.8rem;
  }

  .job-details-page p {
    font-size: 1rem;
  }

  .job-details-page-container .apply-button,
  .job-details-page-container .apply-later-button {
    font-size: 1rem;
    padding: 10px 15px;
  }

  .job-details-page-container .job-status,
  .job-details-page-container .job-description {
    font-size: 1rem;
  }
  
}

@media (max-width: 900px) {
  .job-details-page-container .job-details-page {
    margin: 0 20px;
  }

  .job-details-page h1 {
    font-size: 1.9rem;
  }

  .job-details-page p {
    font-size: 1.05rem;
  }

  .job-details-page-container .apply-button,
  .job-details-page-container .apply-later-button {
    font-size: 1.05rem;
  }

  .job-details-page-container .job-status,
  .job-details-page-container .job-description {
    font-size: 1.05rem;
  }
}
