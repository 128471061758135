.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: #1a1a1a;
    border-radius: 8px;
    width: 90%;
    max-width: 1200px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
}

.modal-header {
    padding: 1.5rem;
    border-bottom: 1px solid #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-header h2 {
    margin: 0;
    color: #edc500;
}

.close-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
}

.modal-body {
    padding: 1.5rem;
    flex: 1;
    overflow-y: auto;
    min-height: 300px;
    color: #fff;
}

.editor-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.editor-controls {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
}

.control-section {
    flex: 1;
    min-width: 300px;
    background: #222;
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid #444;
}

.control-section h3 {
    margin: 0 0 1rem 0;
    color: #edc500;
    font-size: 1.1rem;
}

.branding-controls,
.button-controls {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
}

.text-controls {
    display: flex;
    gap: 1rem;
    flex: 1;
    align-items: center;
}

.color-picker {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.color-picker label, .button-text-change label,.email-button-link label,.email-brand-link label, .email-regards-para label {
    font-size: 0.9rem;
    color: #ccc;
}

.color-picker input[type="color"] {
    padding: 0;
    width: 40px;
    height: 30px;
    border: 1px solid #444;
    border-radius: 4px;
    cursor: pointer;
    background: #333;
}

.button-text-change input{
    width : 200px; 
    height : 30px;
    padding : 0;
    border : 1px solid #444;
    border-radius : 4px;
    background : #333;
    color : white;
}

.email-button-link input,.email-brand-link input,.email-regards-para input{
    width: 300px;
    height: 30px;
    padding : 0 0 0 4px;
    border: 1px solid #444;
    border-radius: 4px;
    background: #333;
    color: white;
}

.button-text-change,.email-button-link,.email-brand-link,.email-regards-para {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

/* select, */
.url-input,
.text-input {
    padding: 0.5rem;
    border: 1px solid #444;
    border-radius: 4px;
    font-size: 0.9rem;
    background: #333;
    color: #fff;
}

/* select:focus, */
.url-input:focus,
.text-input:focus {
    outline: none;
    border-color: #edc500;
}

.editor-preview-container {
    display: flex;
    gap: 1.5rem;
    margin-top: 1rem;
}

.editor-section,
.preview-section {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.editor-section h3,
.preview-section h3 {
    margin: 0 0 0.5rem 0;
    color: #edc500;
}

.quill-editor {
    height: 400px;
    border: 1px solid #444;
    border-radius: 4px;
    background: #222;
}

/* Quill editor dark theme overrides */
.ql-toolbar {
    background: #333;
    border-color: #444 !important;
    border-radius: 4px 4px 0 0;
}

.ql-container {
    border-color: #444 !important;
    background: #222;
    color: #fff;
}

.ql-editor {
    color: #fff;
}

.ql-picker {
    color: #fff !important;
}

.ql-stroke {
    stroke: #fff !important;
}

.ql-fill {
    fill: #fff !important;
}

.preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.refresh-button {
    padding: 0.5rem 1rem;
    background: #333;
    border: 1px solid #444;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    color: #fff;
    transition: all 0.2s;
}

.refresh-button:hover {
    background: #444;
}

.preview-frame {
    width: 100%;
    height: 400px;
    border: 1px solid #444;
    border-radius: 4px;
    background: #fff;
}

.modal-footer {
    padding: 1.5rem;
    border-top: 1px solid #333;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.primary-button,
.secondary-button {
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.primary-button {
    background: #edc500;
    border: none;
    color: #000;
}

.secondary-button {
    background: #333;
    border: none;
    color: #fff;
}

.primary-button:hover {
    background: #dac100;
}

.secondary-button:hover {
    background: #444;
}

@media (max-width: 768px) {
    .modal-content {
        width: 95%;
        max-height: 95vh;
    }

    .editor-preview-container {
        flex-direction: column;
    }

    .modal-header,
    .modal-body,
    .modal-footer {
        padding: 1rem;
    }
}