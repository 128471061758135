:root {
    --input-border-radius: 4px;
    --button-border-radius: 4px;
  }
  
  .primaryBtn {
    cursor: pointer;
    padding: 0.45rem 0.85rem;
    background-color: transparent;
    border: 1px solid #eac500;
    font-weight: 600;
    border-radius: var(--button-border-radius);
    white-space: nowrap;
    font-family: 'Poppins';
    color: #eac500;
    transition: all 100ms ease-in;
  }
  .primaryBtn p,
  .secondaryBtn p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
  .primaryBtn:hover {
    background-color: #222;
    color: #edc500;
  }
  .primaryBtn[disabled] {
    opacity: 0.6; /* Make the button appear faded */
    cursor: not-allowed; /* Change the cursor to indicate it's not clickable */
    pointer-events: none; /* Ensure the button is not interactive */
    background-color: #3d3c3c; /* Optional: Change the background color to a grey shade*/
    /* border-color: #666;  */
  }
  .secondaryBtn[disabled] {
    opacity: 0.6; /* Make the button appear faded */
    cursor: not-allowed; /* Change the cursor to indicate it's not clickable */
    pointer-events: none; /* Ensure the button is not interactive */
    background-color: #3d3c3c; /* Optional: Change the background color to a grey shade*/
    /* border-color: #666;  */
  }
  .secondaryBtn {
    cursor: pointer;
    padding: 0.45rem 0.85rem;
    color: #edc500;
    background-color: #1a1a1a;
    border: none;
    font-weight: 600;
    border-radius: var(--button-border-radius);
    white-space: nowrap;
    font-family: 'Poppins';
    transition: all 100ms ease-in;
  }
  .secondaryBtn:hover {
    background-color: #222;
  }
  
  .reiterateBtn{
    margin: 1rem;
    display: block;
     /* justify-self: end; */
  }
  
  .formError {
    font-family: 'Poppins';
    font-size: 0.75rem;
    color: rgb(220, 52, 52);
  }

  .questionSetNavigation {
    display: flex;
    gap: 10px;
    margin-bottom: 2rem;
  }
  
  .questionSetNavigation button {
    padding: 8px 12px;
    cursor: pointer;
    color: #ddd;
  }
  
  .questionSetNavigation button.active {
    /* background-color: #beaa42; */
    border: 1px solid #edc500;
    color: #fff;
  }
  
  .loaderContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  
  .createInterviewContainer {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .createInterviewContainer .progressBars {
    display: flex;
    width: 100%;
    gap: 0.2rem;
  }
  
  .createInterviewContainer .progressBars div {
    height: 3px;
    width: 100%;
    background: #333;
  }
  
  .createInterviewContainer .progressBars .done {
    background: #ddd;
  }
  
  .createInterviewContainer .progressStatus {
    margin-top: 2rem;
    display: flex;
    gap: 0.25rem;
  }
  
  .createInterviewContainer .progressStatus p {
    display: flex;
    align-items: center;
    color: #666;
    gap: 0.25rem;
    font-family: 'Poppins';
    font-size: 0.9rem;
    cursor: pointer;
  }
  
  .createInterviewContainer .progressStatus p:not(.done):hover {
    color: #eac100cc;
  }
  
  .createInterviewContainer .progressStatus .done {
    color: #ddd;
  }
  
  .createInterviewContainer .createInterviewMain {
    margin: auto;
    width: 80%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .createInterviewContainer .createInterview .createInterviewForm {
    width: 100%;
  }
  
  /* Form Page 1 Fields Styling starts here */
  .createInterviewMain .formPage1 {
    margin-inline: auto;
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .formPage1 .selectField,
  .formPage1 .inputField {
    flex-grow: 1;
  }
  
  .formPage1 .inputField {
    width: 100%;
  }
  .formPage1 .selectField {
    width: 40%;
  }
  .formPage1 .inputField input {
    width: 95%;
  }
  .formPage1 .selectField select {
    width: 100%;
  }
  /* Form Page 1 Fields Styling ends here */
  
  /* Form Page 2 Fields Styling starts here */
  .createInterviewMain .formPage2 {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  
  .formPage2 .textareaField,
  .formPage2 .inputField {
    flex-grow: 1;
  }

  .formPage2 .inputField input {
    width: 90%;
  }
  
  .formPage2 .textareaField {
    width: 40%;
  }
  .formPage2 .textareaField:nth-child(3) {
    width: 100%;
  }
  
  .formPage2 .textareaField textarea {
    field-sizing: fixed;
    width: calc(100% - 30px);
    min-height: 100px;
    background-color: transparent;
    resize: vertical;
  }
  /* Form Page 2 Fields Styling ends here */
  
  /* Form Page 3 Fields Styling starts here */
  .createInterviewMain .formPage3 {
    min-width: 100%;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  
  .formPage3 .multiSelectField {
    min-width: 100%;
  }
  
  .formPage3 .textareaField {
    width: 100%;
  }
  
  .formPage3 .selectField {
    flex-grow: 1;
  }
  
  .formPage3 .selectField select {
    width: 100%;
  }
  
  .formPage3 .textareaField textarea {
    field-sizing: fixed;
    width: calc(100% - 30px);
    min-height: 100px;
    background-color: transparent;
    resize: vertical;
  }
  
  .formPage3 .inputField input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  /* Form Page 3 Fields Styling ends here */
  
  /* Form Page 4 Fields Styling starts here */
  .createInterviewForm .formPage4 {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 1rem;
  }
  
  .createInterviewMain .createInterviewForm .formPage4 .scrollableContainer > .textareaField textarea {
    min-width: calc(100% - 30px);
    resize: vertical;
  }
  
  .formPage4 .generatedQuestions {
    width: 100%;
    display: flex;
    gap: 4rem;
    justify-content: space-between;
  }
  
  .createInterviewMain .formPage4 .generatedQuestionsContainer {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .formPage4 .generatedQuestionsContainer .generatedQuestionsList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .formPage4 .textareaField .inputActionButton {
    display: grid;
    align-items: center;
    background-color: #181818;
    border: none;
    position: absolute;
    right: -0.2rem;
    top: -0.2rem;
    padding-block: 0.4rem;
    border-radius: 4px;
    border: 1px solid #444;
    cursor: pointer;
  }
  
  .formPage4 .textareaField .inputActionButton:hover {
    background-color: #282828;
  }
  
  .formPage4 .generatedQuestionsList .textareaField textarea {
    width: calc(100% - 26px);
    height: fit-content;
    resize: none;
    border-radius: 4px;
    border: 1px solid #222;
  }
  
  .formPage4 .generatedQuestionsList .textareaField textarea:focus {
    background-color: #222;
    border: 1px solid #222;
  }
  /* Form Page 4 Fields Styling ends here */
  
  /* General Input Styling starts here */
  .createInterviewContainer .createInterviewMain .inputField,
  .createInterviewContainer .createInterviewMain .selectField,
  .createInterviewContainer .createInterviewMain .textareaField {
    position: relative;
    padding: 0.25rem 0.75rem 0.25rem 0.25rem;
    border: 1px solid #444;
    border-radius: var(--input-border-radius);
  }
  
  .createInterviewContainer .createInterviewMain .imageSelectField {
    width: fit-content;
    display: flex;
    gap: 0.5rem;
    position: relative;
    padding: 0.75rem;
    border: 1px solid #444;
    border-radius: var(--input-border-radius);
  }
  
  .createInterviewMain .imageSelectField div,
  .createInterviewMain .imageSelectField img {
    border-radius: 4px;
  }
  .createInterviewMain .imageSelectField div {
    box-sizing: content-box;
    cursor: pointer;
    padding: 0.2rem 0.2rem 0 0.2rem;
  }
  
  .createInterviewMain .imageSelectField .selected {
    outline: 2px solid #edc500;
  }
  
  .createInterviewMain .inputField input,
  .createInterviewMain .selectField select {
    background-color: transparent;
    padding: 0.5rem 1rem;
    color: #ddd;
    border: none;
    font-family: 'Poppins';
  }
  
  .createInterviewMain .textareaField textarea {
    background-color: transparent;
    padding: 0.5rem 1rem;
    color: #ddd;
    border: none;
    font-family: 'Poppins';
  }
  
  .createInterviewMain .inputField input:focus,
  .createInterviewMain .selectField select:focus, 
  .createInterviewMain .textareaField textarea:focus {
    border: none;
    outline: none;
  }
  
  .createInterviewMain .selectField select:focus {
    background-color: #161616;
  }

  .createInterviewMain .inputField:has(input:focus),
  .createInterviewMain .selectField:has(select:focus),
  .createInterviewMain .textareaField:has(textarea:focus) {
    border-color: #edc500;
  }
  
  .createInterviewMain .fieldLabel {
    position: absolute;
    top: -0.5rem;
    background-color: #1d1d1d;
    color: #ddd;
    padding-inline: 0.3rem;
    left: 0.5rem;
    font-size: 0.7rem;
    font-weight: 200;
    font-family: 'Poppins';
  }
  
  .createInterviewForm .actions {
    margin-left: 10rem;
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    align-items: center;
  }
  
  .createInterviewForm .actions .secondaryBtn {
    margin-left: auto;
  }