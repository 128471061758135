@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.job-details-container-outer {
  background: linear-gradient(60deg, #000, #222, #000);
  /* height: 100vh; */
  width: 100%;
  padding: 20px;
}

.job-details-container-outer .dashboard-navbar {
  color: white;
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  padding: 20px 10px;
  gap: 10px;
}
/* 
.job-details-container-outer .dashboard-navbar .logo {
  font-size: 24px;
  font-weight: bold;
}

.job-details-container-outer .dashboard-navbar .logo .logo-peh {
  color: #fff;
}

.job-details-container-outer .dashboard-navbar .logo-chaan {
  color: #edc500;
} */

.job-details-container-outer .dashboard-navbar .job-listings-nav {
  text-decoration: none;
  color: #999999;
  margin-right: 10px;
}

.job-details-container-outer .job-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 10px;
  margin-bottom: 10px;
}

.tab-content .btn {
  text-decoration: none;
  padding: 10px;
  background-color: #222;
  border-radius: 5px;
  color: #fff623;
  margin-right: 20px;
  cursor : pointer;
}

.tab-content .btn:hover {
  background-color: #333333;
  /* Slightly lighter shade for hover */
  color: #f0e68c;
  /* Complementary color on hover for contrast */
  border-color: #b59a24;
  /* Slightly lighter border color */
}

.tab-content .applicants-header{
  display : flex;
  /* justify-content : space-between; */
  /* align-items : center; */
  flex-direction : column;
  width : 300px;
  gap : 10px;
}
.job-details-container-outer .job-description .btn:hover {
  background-color: #111;
  color: #fef623;
}

/* Published date adjustments */
.job-details-container-outer .published-date {
  margin-bottom: 40px;
  /* Add margin below the published date */
}

.job-details-container-outer h2,
.job-details-container-outer h3,
.job-details-container-outer h4 {
  color: #fff;
  /* Lighter text for headings */
}
/* 
.job-details-container-outer p,
.job-details-container-outer span {
  color: #ddd;
} */

.job-details-container-outer .tag {
  padding: 10px;
  background-color: #282828;
  margin-left: 10px;
  border-radius: 5px;
  color: #fff;
}

.job-details-container-outer .interviews-list {
  list-style-type: none;
  /* padding: 0; */
  /* margin-right: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.job-details-container-outer .interviews-list > li {
  /* padding: 15px 40px 15px 20px; */
  /* margin: 0 20px; */
  margin-bottom: 15px;
  background-color: #222;
  /* Slightly darker background */
  border-radius: 5px;
  /* max-width: 1400px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Changed to column for better stacking */
}

.job-details-container-outer .interviews-list .interview-name-and-toggle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.job-details-container-outer .interviews-list li .toggle-view-hide {
  color: #fff623;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.job-details-container-outer .interviews-list li .btn:hover {
  background-color: #c28753;
  /* Hover effect */
}

.job-details-container-outer .interview-details {
  padding: 15px;
  margin-top: 10px;
  background-color: #222;
  /* Slightly darker for contrast */
  border-left: 4px solid #fff623;
  /* Left border for highlighting */
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  /* Stack details vertically */
}

.job-details-container-outer .instructions-container {
  margin: 10px;
  padding: 10px;
  font-size: 20px;
}

.job-details-container-outer .instructions-container h4 {
  font-size: 20px;
}

.job-details-container-outer .instructions-container h5 {
  font-size: 18px;
}

.job-details-container-outer .instructions-container p {
  font-size: 16px;
}

.questions-list {
  list-style-type: none;
  padding: 0;
}

.questions-list li {
  padding: 10px 0;
  border-bottom: 1px solid #666;
  display: flex;
}

.job-details-container-outer .questions-list .left {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.job-details-container-outer .questions-list .right {
  width: 30%;
}

.iframe-video {
  width: 100%;
  min-width: 200px;
  height: 450px;
  margin-top: 10px;
  border-radius: 4px;
  border: none;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  width: 100%;
  height: auto;

}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #edc500;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

.loader-text {
  margin-top: 10px;
  color: #fff;
  font-size: 18px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.job-details-container-outer .job-title-container{
  display : flex;
}

.job-details-container-outer .job-title-container .copy-url-container{
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content : center;
  margin-left : 20px;
}
.job-details-container-outer .job-title-container .copy-url-container button {
  background-color: #222;
  color: #fff623;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #877311;
  cursor: pointer;
  /* Smooth transition */
}

.job-details-container-outer .job-title-container .copy-url-container button:hover {
  background-color: #333333;
  /* Slightly lighter shade for hover */
  color: #f0e68c;
  /* Complementary color on hover for contrast */
  border-color: #b59a24;
  /* Slightly lighter border color */
}

/* Tabs styles */
.tabs-container {
  background: #1a1a1a;
  border-radius: 8px;
  overflow: hidden;
 margin-top: -20px;
}

.tabs {
  display: flex;
  background: #222;
  padding: 0;
  margin: 0;
}

.tab {
  padding: 0.5rem 1.5rem;
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  transition: all 0.3s ease;
  position: relative;
}

/* .tab:not(:last-child) {
  border-right: 1px solid #333;
} */

.tab:hover {
  color: #edc500;
}

.tab.active {
  color: #edc500;
  background: #1a1a1a;
}

.tab.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #edc500;
}

/* Tab content styles */
.tab-content {
  /* padding: 2rem; */
  /* margin-right : 20px; */
  min-height: 100vh;
}

.job-header {
  margin-bottom: 2rem;
}

.tab-link {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background: #222;
  color: #edc500;
  text-decoration: none;
  border: 1px solid #edc500;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.tab-link:hover {
  background: #333;
}

/* Settings styles */
.settings-section {
  margin-bottom: 2rem;
}

.settings-section h4 {
  color: #edc500;
  margin-bottom: 1rem;
}

.setting-item {
  margin-bottom: 1rem;
}

.setting-item label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.setting-item input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

/* Job content styles */
/* .job-content {
  margin-right : 20px; 
  padding-right : 2rem;
} */

/* .date-tag {
  display: inline-block;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  background: #222;
  border-radius: 4px;
  font-size: 0.9rem;
} */

/* Settings specific styles */
.settings-section {
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: #222;
  border-radius: 8px;
}

.settings-section h4 {
  color: #edc500;
  margin-bottom: 1rem;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.instructions-textarea {
  width: 100%;
  min-height: 150px;
  padding: 1rem;
  background: #333;
  border: 1px solid #444;
  border-radius: 4px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  margin-top: 1rem;
}

.instructions-display {
  background: #333;
  padding: 1rem;
  border-radius: 4px;
  white-space: pre-wrap;
  margin-top: 1rem;
}

.settings-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.settings-actions button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.edit-button,
.email-templates-button {
  background: #222;
  border: 1px solid #edc500;
  color: #edc500;
  padding : 0.5rem 1rem; 
  cursor : pointer;
}

.save-button {
  background: #edc500;
  border: none;
  color: #000;
}

.cancel-button {
  background: #444;
  border: none;
  color: #fff;
}
/* Responsive styles */
@media (max-width: 768px) {
  .navbar {
    padding: 1rem;
  }

  .job-content {
    padding: 0 1rem;
  }

  .tabs {
    flex-wrap: wrap;
  }

  .tab {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
    flex: 1 1 auto;
    text-align: center;
  }

  .tab-content {
    padding: 1rem;
  }

  .job-header h1 {
    font-size: 1.5rem;
  }

  .settings-actions {
    flex-direction: column;
  }

  .settings-actions button {
    width: 100%;
  }
}

.loader-on-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #ffd700;
  /* Yellow color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}