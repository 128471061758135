.education-container {
    text-align: center;
    padding: 20px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  .education-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #333;
    color: #fff;
    border-radius: 10px;
  }
  
  .education-text {
    flex: 1;
    text-align: left;
  }
  
  .education-actions {
    display: flex;
    gap: 10px;
  }
  
  .icon-button {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-color: #d69c5a;
    padding: 5px;
    border-radius: 50%;
  }
  
  .icon-button img {
    width: 100%;
    height: 100%;
  }
  
  .add-more-button {
    padding: 10px 20px;
    background-color: #d69c5a;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 20px;
    margin-top: 20px;
  }