:root {
	--input-border-radius: 4px;
	--button-border-radius: 4px;
}

a {
	text-decoration: none;
	color: #dac100;
}

h1,
h2,
h3,
h4 {
	font-family: 'Poppins';
}

.primaryBtn {
	cursor: pointer;
	padding: 0.45rem 0.85rem;
	background-color: transparent;
	border: 1px solid #eac500;
	font-weight: 600;
	border-radius: var(--button-border-radius);
	white-space: nowrap;
	font-family: 'Poppins';
	color: #eac500;
	transition: all 100ms ease-in;
}

.primaryBtn p,
.secondaryBtn p {
	margin-block-start: 0;
	margin-block-end: 0;
}

.primaryBtn:hover {
	background-color: #222;
	color: #edc500;
}

.primaryBtn[disabled] {
	opacity: 0.6;
	/* Make the button appear faded */
	cursor: not-allowed;
	/* Change the cursor to indicate it's not clickable */
	pointer-events: none;
	/* Ensure the button is not interactive */
	background-color: #3d3c3c;
	/* Optional: Change the background color to a grey shade*/
	/* border-color: #666;  */
}

.secondaryBtn[disabled] {
	opacity: 0.6;
	/* Make the button appear faded */
	cursor: not-allowed;
	/* Change the cursor to indicate it's not clickable */
	pointer-events: none;
	/* Ensure the button is not interactive */
	background-color: #3d3c3c;
	/* Optional: Change the background color to a grey shade*/
	/* border-color: #666;  */
}

.secondaryBtn {
	cursor: pointer;
	padding: 0.45rem 0.85rem;
	color: #edc500;
	background-color: #1a1a1a;
	border: none;
	font-weight: 600;
	border-radius: var(--button-border-radius);
	white-space: nowrap;
	font-family: 'Poppins';
	transition: all 100ms ease-in;
}

.secondaryBtn:hover {
	background-color: #222;
}

.loaderContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }

.editJobContainer {
	min-height: 100vh;
    width: 100vw;
	display: flex;
	flex-direction: column;
	background-color: #111;
}

.editJobContainer .navbar {
	padding: 2rem;
	display: flex;
	justify-content: space-between;
}

.editJobContainer .editJobMain {
	min-height: 70vh;
    width: 80%;
	box-sizing: border-box;
    margin-inline: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;
}

.editJobMain .progressBars {
	display: flex;
	width: 100%;
	gap: 0.2rem;
}

.editJobMain .progressBars div {
	height: 3px;
	width: 100%;
	background: #333;
}

.editJobMain .progressBars .done {
	background: #ddd;
}

.editJobMain .progressStatus {
	margin-top: 2rem;
	display: flex;
	gap: 0.25rem;
}

.editJobMain .progressStatus p {
	display: flex;
	align-items: center;
	color: #666;
	gap: 0.25rem;
	font-family: 'Poppins';
	font-size: 0.9rem;
	cursor: pointer;
}

.editJobMain .progressStatus p:not(.done):hover {
	color: #eac100cc;
}

.editJobMain .progressStatus .done {
	color: #ddd;
}

.editJobMain .editJobForm {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

/* Form Page 1 styling starts here */
.editJobMain .formPage1 {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
}

.editJobMain .formPage1 .inputField {
	max-width: 300px;
}

.editJobMain .formPage1 .inputField input {
	width: calc(100% - 30px);
}

.editJobMain .formPage1 .selectField {
	width: fit-content;
}

.editJobMain .formPage1 .textareaField {
	width: 98%;
}

.editJobMain .formPage1 .textareaField textarea {
	min-height: 300px;
	width: calc(100% - 30px);
	resize: vertical;
}
/* Form Page 1 styling ends here */


/* Form Page 2 and 3 styling ends here */
.editJobMain .formPage2,
.editJobMain .formPage3 {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	gap: 1rem;
}

.editJobMain .formPage2 .selectField,
.editJobMain .formPage3 .selectField {
	width: 180px;
}

.editJobMain .formPage2 .selectField select,
.editJobMain .formPage3 .selectField select {
	width: 100%;
}
.editJobMain .formPage2 .buttonField,
.editJobMain .formPage3 .buttonField {
	width: fit-content;
}

.editJobMain .formPage2 .buttonField button,
.editJobMain .formPage3 .buttonField button {
	height: 100%;
}

.editJobMain .formPage2 .textareaField textarea,
.editJobMain .formPage3 .textareaField textarea {
	width: 100%;
	field-sizing: content;
	resize: none;
}

.editJobMain .formPage2 .textareaField,
.editJobMain .formPage3 .textareaField {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.editJobMain .formPage2 .textareaContainer,
.editJobMain .formPage3 .textareaContainer {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	min-height: 44px;
	width: 98%;
}
/* Form Page 2 styling ends here */


/* General Input Styling starts here */
.editJobContainer .editJobMain .inputField,
.editJobContainer .editJobMain .selectField,
.editJobContainer .editJobMain .textareaField {
	position: relative;
	padding: 0.25rem 0.75rem 0.25rem 0.25rem;
	border: 1px solid #444;
	border-radius: var(--input-border-radius);
}

.editJobMain .inputField input,
.editJobMain .selectField select {
	background-color: transparent;
	padding: 0.5rem 1rem;
	color: #ddd;
	border: none;
	font-family: 'Poppins';
}

.editJobMain .textareaField textarea {
	background-color: transparent;
	padding: 0.5rem 1rem;
	color: #ddd;
	border: none;
	font-family: 'Poppins';
	field-sizing: content;
}

.editJobMain .inputField input:focus,
.editJobMain .selectField select:focus,
.editJobMain .textareaField textarea:focus {
	border: none;
	outline: none;
}

.editJobMain .selectField select:focus {
	background-color: #161616;
}

.editJobMain .inputField:has(input:focus),
.editJobMain .selectField:has(select:focus),
.editJobMain .textareaField:has(textarea:focus) {
	border-color: #edc500;
}

.editJobMain .fieldLabel {
	position: absolute;
	top: -0.5rem;
	background-color: #1d1d1d;
	color: #ddd;
	padding-inline: 0.3rem;
	left: 0.5rem;
	font-size: 0.7rem;
	font-weight: 200;
	font-family: 'Poppins';
}

.formError {
    font-family: 'Poppins';
    font-size: 0.75rem;
    color: rgb(220, 52, 52);
  }

.editJobForm .actions {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    align-items: center;
  }
  
  .editJobForm .actions .secondaryBtn {
    margin-left: auto;
  }

/* General Input Styling ends here */


.error-container {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: linear-gradient(60deg, #000, #222, #000);
	color: white;
	gap: 1rem;
}