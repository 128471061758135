.client-email-container {
    min-height: 100vh;
    background: linear-gradient(60deg, #000, #222, #000);
    color: #fff;
    font-family: 'Poppins', sans-serif;
}

.client-email-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.error {
    background: rgba(255, 59, 48, 0.1);
    border: 1px solid rgba(255, 59, 48, 0.3);
    color: #ff3b30;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 4px;
}

.client-email-loader {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(60deg, #000, #222, #000);
    color: #fff;
}

.client-email-spinner {
    border: 4px solid rgba(237, 197, 0, 0.1);
    border-top: 4px solid #edc500;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: custom-spin-argha 1s linear infinite;
    margin-bottom: 1rem;
}

@keyframes custom-spin-argha {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 768px) {
    .client-email-content {
        padding: 1rem;
    }
}

/* Search Bar CSS */
.searchContainer {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin-bottom: 2rem;
}

.searchInput {
    width: 100%;
    padding: 0.75rem 1rem 0.75rem 2.5rem;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    color: #fff;
    font-size: 1rem;
}

.searchInput::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.searchIcon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    color: rgba(255, 255, 255, 0.5);
}



/* Header CSS */
.header {
    padding: 1.5rem 2rem;
    background: rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerLeft {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}

.logoPeh {
    color: #fff;
}

.logoChaan {
    color: #edc500;
}

.breadcrumb {
    color: #999;
}

.breadcrumb a {
    color: #999;
    text-decoration: none;
}

.breadcrumb a:hover {
    color: #edc500;
}

.brandingButton {
    padding: 0.75rem 1.5rem;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.2s;
}

.brandingButton:hover {
    background: rgba(255, 255, 255, 0.15);
    border-color: #edc500;
}

.brandingButton svg {
    color: #edc500;
}

/* Template List CSS */
.templateList {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 2rem;
}

.sectionTitle {
    color: #edc500;
    margin: 1.5rem 0;
    /* font-size: 1.5rem; */
    padding-left: 1rem;
}

.listHeader {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.3);
    font-weight: 600;
    color: #edc500;
}

.templateItem {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    align-items: center;
}

.templateItem:hover {
    background: rgba(255, 255, 255, 0.05);
}

.actionButtons {
    display: flex;
    gap: 0.5rem;
}

.actionButton {
    width : 150px;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
    font-weight: 500;
}

.actionButton.view {
    background: transparent;
    border: 1px solid #edc500;
    color: #edc500;
}

.actionButton.view:hover {
    background: rgba(237, 197, 0, 0.1);
}

.actionButton:not(.view) {
    background: #edc500;
    border: none;
    color: #000;
}

.actionButton:not(.view):hover {
    background: #ffd700;
}