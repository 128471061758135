/* Specificity added for create-job container */
.create-instructions-container {
	display: flex;
	flex-direction: column;
	height: 100vh;
	background: linear-gradient(60deg, #000, #222, #000);
	animation: color 5s ease-in-out infinite;
	overflow: scroll;
}

.loader-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	text-align: center;
}

.loader {
	border: 4px solid #f3f3f3;
	border-top: 4px solid #edc500;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;
}

@keyframes color {
	0% {
		background-position: 0 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0 50%;
	}
}

/* Specificity added for create-instructions navbar */
.create-instructions-container .create-instructions-navbar {
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 30px;
}

.create-instructions-container .logo {
	font-size: 24px;
	font-weight: bold;
	text-decoration: none;
}

.create-instructions-container .logo-peh {
	color: #fff;
}

.create-instructions-container .logo-chaan {
	color: #edc500;
}

/* Specificity added for navbar links */
.create-instructions-container .nav-link {
	color: white;
	text-decoration: none;
	margin: 0 10px;
	font-size: 18px;
}
.create-instructions-container .create-instructions-navbar h2{
	display: flex;
	align-items: flex-end;
	justify-content: right;
	font-size: 18px;

}

.create-instructions-container .nav-link:hover {
	color: #e8990f;
}

/* Specificity added for create-instructions main */
.create-instructions-container .create-instructions-main {
	flex: 1;
	padding: 20px;
	margin-inline: auto;
	width: 60%;
}

.create-instructions-container .create-instructions-main h1 {
	display: flex;
	/* align-items: center; */
	justify-content: center;
	font-size: 40px;
	color: white; /* Ensure text remains visible */
}

/* Specificity added for h2 tags */
.create-instructions-container .create-instructions-main h2 {
	color: white;
}


.create-instructions-main .instructions-form {
		position: relative;
		width: 100%;
		display: flex;
		gap: 0 0.75rem;
		align-items: stretch;
	flex-wrap: wrap;
}

.instructions-form .form-group {
	position: relative;
	width: 48%;
	margin-top: 15px;
	flex-grow: 1;
}

.instructions-form .form-group input,
.instructions-form .form-group select,
.instructions-form .form-group textarea {
	box-sizing: border-box;
	width: 100%;
	padding: 0.75rem 1.25rem;
	border: 1px solid #ccc;
	border-radius: 10px;
	color: #fff;
	font-family: 'Poppins';
	background: none;
	font-size: 14px;
	outline: none;
	background-color: transparent;
}

.instructions-form .form-group.textarea {
	width: 100%;
}

.instructions-form .form-group.textarea textarea {
	width: 100%;
	resize: vertical;
}

.instructions-form .form-group select {
	background: #222;
}

.instructions-form input:focus,
.instructions-form select:focus {
	border-color: #fff623;
	outline: none;
}

.instructions-form .form-group label {
	position: absolute;
	top: 25px;
	left: 20px;
	transform: translateY(-50%);
	font-size: 16px;
	color: #ccc;
	transition: 0.2s;
	pointer-events: none;
}

.form-group input:focus ~ label,
.form-group input:valid ~ label,
.form-group select:focus ~ label,
.form-group select:valid ~ label {
	top: 0;
	font-size: 12px;
	color: #fff;
	background-color: #222;
}

.instructions-form button {
	margin-top: 1rem; 
	margin-left: auto;
	max-width: 400px;
	padding: 10px 15px;
	font-size: 1rem;
	background-color: #222;
	border: solid #edc500;
	color: #dac100;
	border-width: 0.1px;
	border-radius: 5px;
	cursor: pointer;
}

.instructions-form button:hover {
	background-color: #edc500;
	color: #000;
}

button.disabled {
		opacity: 0.5;
		cursor: not-allowed;
}