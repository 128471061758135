@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");

.candidates-list-container {
    background: linear-gradient(60deg, #000, #222, #000);
    min-height: 100vh;
    color: white;
    /* padding: 20px; */
}

.poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
}

/* Navbar Styles */
.dashboard-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.logo-text {
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
    text-decoration: none;
}

.job-listings-nav {
    color: #999;
    text-decoration: none;
    transition: color 0.3s ease;
    margin-right: 10px;
}

.job-listings-nav:hover {
    color: #fff;
}

.export .btn {
    color: #fff623;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #222;
    padding: 10px 20px;
    margin: 20px;
    border: solid 0.5px #fff623;
    border-radius: 8px;
}

.export .btn:hover {
    background: rgba(255, 246, 35, 0.1);
}

.dropdown-menu {
    position: absolute;
    padding: 10px 10px;
    margin: 0px 20px;
    background-color: #222;
    /* border: 0.1px solid #fff623; */
    border-radius: 4px;
    z-index: 1000;
}

.dropdown-menu button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    padding: 0.75rem 1.5rem;
    background: transparent;
    border: none;
    color: #fff623;
    cursor: pointer;
    transition: background 0.3s ease;
}

.dropdown-menu button:hover {
    background: rgba(255, 246, 35, 0.1);
}

/* Main Content Styles */
.dashboard-main {
    padding: 2rem;
    padding-top: 2px;
}

.tag-and-candidates {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.tag {
    background: rgba(255, 255, 255, 0.1);
    padding: 0.5rem 1rem;
    border-radius: 6px;
    font-size: 0.9rem;
}

.candidates h3 {
    color: #999;
    font-size: 1rem;
    font-weight: 400;
}

/* Grid Styles */
.ag-theme-quartz-dark {
    --ag-background-color: #222;
    --ag-header-background-color: #333;
    --ag-odd-row-background-color: #2a2a2a;
    --ag-header-foreground-color: #fff;
    --ag-foreground-color: #fff;
    --ag-row-hover-color: #333;
    --ag-selected-row-background-color: #444;
    --ag-font-family: 'Poppins', sans-serif;
    border-radius: 8px;
    overflow: hidden;
}

/* Stage Badge Styles */
.stage-badge {
    padding: 0.25rem 0.75rem;
    border-radius: 999px;
    font-size: 0.85rem;
    font-weight: 500;
    text-align: center;
}

.stage-badge.signed-up {
    background: rgba(33, 150, 243, 0.2);
    color: #2196F3;
}

.stage-badge.round-1 {
    background: rgba(76, 175, 80, 0.2);
    color: #4CAF50;
}

.stage-badge.round-2 {
    background: rgba(255, 167, 38, 0.2);
    color: #FFA726;
}

/* Candidate Link Styles */
.candidate-link {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.candidate-link:hover {
    color: #fff623;
}

/* Loading Styles */
.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #111;
}

.loader {
    width: 48px;
    height: 48px;
    border: 4px solid #fff;
    border-top-color: #fff623;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.loader-text {
    margin-top: 1rem;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Responsive Styles */
@media (max-width: 768px) {
    .dashboard-navbar {
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
    }

    .tag-and-candidates {
        flex-direction: column;
        gap: 1rem;
        text-align: center;
    }

    .export {
        width: 100%;
    }

    .export .btn {
        width: 100%;
        justify-content: center;
    }
}

.tags-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.reports-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: #222;
    ;
    border: 1px solid #fff623;
    color: #fff623;
    border-radius: 6px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    transition: all 0.3s ease;
}

.reports-btn:hover {
    background: rgba(255, 246, 35, 0.1);
}

@media (max-width: 768px) {
    .tags-container {
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
    }

    .reports-btn {
        width: 100%;
        justify-content: center;
    }
}

.status-boxes {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    gap: 1rem;
}

.status-box {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 1rem;
    flex: 1;
    text-align: center;
    transition: all 0.3s ease;
}

.status-box:hover {
    background: rgba(255, 246, 35, 0.1);
}

.status-box h3 {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    color: #fff623;
}

.status-box p {
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
}

@media (max-width: 768px) {
    .status-boxes {
        flex-direction: column;
    }
}

.cv-download-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: #222;
    border: 1px solid #FFF620;
    /* Adjusted to a brighter yellow for better contrast */
    color: #FFF620;
    border-radius: 4px;
    /* Slightly rounded corners */
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    transition: all 0.3s ease;
}

.cv-download-btn:hover {
    background: #333;
    /* Slightly lighter to indicate an active state */
    color: #fff;
    border-color: #ffeb3b;
    /* Softer yellow for hover effect */
}

.cv-download-btn-disabled {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #2a2a2a;
    /* Darker grey background for a cohesive look */
    border: 1px solid #4a4a4a;
    /* Darker border to blend with the design */
    color: #888888;
    /* Light grey text */
    cursor: not-allowed;
    opacity: 0.6;
    /* Subtle opacity */
    border-radius: 4px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
}

.cv-download-btn svg,
.cv-download-btn-disabled svg {
    margin-right: 5px;
}

.shortlist-reject-email-button-candidateList {
    font-size: 16px;
    margin-left: 10px;
    padding: 8px;
    background-color: #222;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.shortlist-reject-email-button-candidateList:hover {
    background-color: #111;
    color: #edc500;
}