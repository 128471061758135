.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 1rem;
    overflow-y: auto;
}

.modal-content {
    background: #1a1a1a;
    border-radius: 8px;
    width: 100%;
    max-width: 900px;
    max-height: calc(100vh - 2rem);
    margin: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}

.modal-header {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1a1a1a;
    border-radius: 8px 8px 0 0;
    position: sticky;
    top: 0;
    z-index: 10;
}

.modal-header h2 {
    margin: 0;
    color: #edc500;
    font-size: 1.25rem;
}

.close-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
}

.modal-body {
    padding: 1.5rem;
    overflow-y: auto;
    color: #fff;
    flex: 1;
}

.branding-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    max-width: 100%;
}

.form-section {
    background: #222;
    padding: 1.25rem;
    border-radius: 6px;
    border: 1px solid #444;
}

.form-section h3 {
    color: #edc500;
    margin: 0 0 1rem 0;
    font-size: 1.1rem;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group:last-child {
    margin-bottom: 0;
}

.form-group label {
    display: block;
    color: #ccc;
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
}

.input-field {
    width: 100%;
    padding: 0.5rem;
    background: #333;
    border: 1px solid #444;
    border-radius: 4px;
    color: #fff;
    font-size: 0.9rem;
}

.color-picker {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.color-input {
    width: 40px;
    height: 32px;
    padding: 0;
    border: 1px solid #444;
    border-radius: 4px;
    cursor: pointer;
}

.modal-footer {
    padding: 1rem 1.5rem;
    border-top: 1px solid #333;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    background: #1a1a1a;
    border-radius: 0 0 8px 8px;
    position: sticky;
    bottom: 0;
    z-index: 10;
}

.button {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
}

.primary-button {
    background: #edc500;
    border: none;
    color: #000;
}

.secondary-button {
    background: #333;
    border: none;
    color: #fff;
}

.primary-button:hover {
    background: #dac100;
}

.secondary-button:hover {
    background: #444;
}

.radio-group {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.5rem;
}

.radio-option {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

@media (max-width: 768px) {
    .modal-content {
        max-height: calc(100vh - 1rem);
        margin: 0.5rem;
    }

    .branding-form {
        grid-template-columns: 1fr;
    }

    .modal-body {
        padding: 1rem;
    }
}