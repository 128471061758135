.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #1a1a1a;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    color: #fff;
}

.modal-content h2 {
    color: #edc500;
    margin-bottom: 1.5rem;
}

.modal-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-group label {
    color: #fff;
    font-size: 0.9rem;
}

.form-group input {
    padding: 0.75rem;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    color: #fff;
    font-size: 1rem;
}

.form-group input:focus {
    outline: none;
    border-color: #edc500;
}

.error-message {
    color: #ff3b30;
    font-size: 0.9rem;
    margin-top: 0.5rem;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1.5rem;
}

.cancel-button,
.save-button {
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s;
}

.cancel-button {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
}

.save-button {
    background: #edc500;
    border: none;
    color: #000;
}

.cancel-button:hover {
    border-color: #fff;
}

.save-button:hover {
    background: #ffd700;
}