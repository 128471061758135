.container {
    min-height: 100vh;
    background: linear-gradient(60deg, #000, #222, #000);
    color: #fff;
    font-family: 'Poppins', sans-serif;
}

.content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.input {
    flex: 1;
    padding: 0.75rem;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    color: #fff;
    font-size: 1rem;
}

.input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.createButton {
    padding: 0.75rem 1.5rem;
    background: #edc500;
    border: none;
    border-radius: 4px;
    color: #000;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    width : auto;
}

.createButton:hover {
    background: #ffd700;
}

.sectionTitle {
    color: #edc500;
    margin-top : 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    padding-left : 20px;
}

.templateList {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    overflow: hidden;
}

.listHeader {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.3);
    font-weight: 600;
    color: #edc500;
}

.templateItem {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    align-items: center;
    transition: background-color 0.2s;
}

.templateItem:hover {
    background: rgba(255, 255, 255, 0.05);
}

.templateItem:last-child {
    border-bottom: none;
}

.editButton {
    padding: 0.5rem 1rem;
    background: #FFD700;
    color: #000000;
    border: 1px solid #edc500;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
}

.editButton:hover {
    background: #edc500;
    color: #000;
}

.error {
    background: rgba(255, 59, 48, 0.1);
    border: 1px solid rgba(255, 59, 48, 0.3);
    color: #ff3b30;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.error button {
    background: none;
    border: none;
    color: #ff3b30;
    cursor: pointer;
    font-size: 1.2rem;
}

.loader {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(60deg, #000, #222, #000);
    color: #fff;
}

.spinner {
    border: 4px solid rgba(237, 197, 0, 0.1);
    border-top: 4px solid #edc500;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.topSection {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
}

/* .createSection {
    flex: 1;
    padding: 1.5rem;
    border-radius: 8px;
    display: flex;
    gap: 1rem;
} */
.createSection {
    display: flex;
    justify-content: flex-end;
    padding: 0;
}

.header {
    padding: 1.5rem 2rem;
    background: rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}

.logoPeh {
    color: #fff;
}

.logoChaan {
    color: #edc500;
}

.searchContainer {
    position: relative;
    width: 100%;
    max-width: 400px;
    display: flex;
    align-items: center;
    /* Aligns items vertically */
}

.searchInput {
    width: 100%;
    padding: 0.75rem 1rem 0.75rem 2.5rem;
    /* Space for the icon on the left */
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    color: #fff;
    font-size: 1rem;
    line-height: 1.5;
    transition: all 0.2s;
    box-sizing: border-box;
    /* Ensure padding and width are consistent */
}

.searchInput::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.searchInput:focus {
    outline: none;
    border-color: #edc500;
    background: rgba(255, 255, 255, 0.15);
}

.searchIcon {
    position: absolute;
    left: 0.75rem;
    /* Keeps the icon inside the input */
    top: 50%;
    transform: translateY(-50%);
    /* Perfectly centers the icon */
    width: 1rem;
    height: 1rem;
    color: rgba(255, 255, 255, 0.5);
    pointer-events: none;
}

@media (max-width: 768px) {
    .content {
        padding: 1rem;
    }

    .topSection {
        flex-direction: column;
    }

    .listHeader,
    .templateItem {
        grid-template-columns: 1fr auto;
        gap: 0.5rem;
    }

    .listHeader div:nth-child(2),
    .templateItem div:nth-child(2) {
        display: none;
    }
}

.displayNameContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.editDisplayNameButton {
    padding: 0.25rem 0.5rem;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 0.8rem;
    transition: all 0.2s;
}

.editDisplayNameButton:hover {
    border-color: #edc500;
    color: #edc500;
}

.displayNameEdit {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.displayNameInput {
    padding: 0.5rem;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    color: #fff;
    font-size: 0.9rem;
}

.saveDisplayNameButton,
.cancelDisplayNameButton {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.8rem;
    transition: all 0.2s;
}

.saveDisplayNameButton {
    background: #edc500;
    border: none;
    color: #000;
}

.cancelDisplayNameButton {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
}

.saveDisplayNameButton:hover {
    background: #ffd700;
}

.cancelDisplayNameButton:hover {
    border-color: #fff;
}