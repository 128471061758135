/* Specificity added for create-job container */

a {
	text-decoration: none;
	color: #dac100;
}

button.primary {
	cursor: pointer;
	padding: 0.65rem 1rem;
	background-color: #edc500;
	border: none;
	font-weight: 600;
	border-radius: 10px;
	white-space: nowrap;
	font-family: 'Poppins';
	transition: all 100ms ease-in;
}

button.primary:hover {
	background-color: #222;
	color: #edc500;
}

button.secondary {
	cursor: pointer;
	padding: 0.65rem 1rem;
	color: #edc500;
	background-color: transparent;
	border: none;
	font-weight: 600;
	border-radius: 10px;
	white-space: nowrap;
	font-family: 'Poppins';
	transition: all 100ms ease-in;
}

button.secondary:hover {
	background-color: #222;
}

/* Specificity added for create-questions navbar */
.create-questions-container .logo {
	font-size: 24px;
	font-weight: bold;
	text-decoration: none;
}

.create-questions-container .logo-peh {
	color: #fff;
}

.create-questions-container .logo-chaan {
	color: #edc500;
}

.create-questions-container .create-questions-navbar {
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2rem 2.5rem;
	gap: 10px;
	border-bottom: 1px solid #333;
}
.create-questions-container .create-questions-navbar .interviews {
	text-decoration: none;
	color: #999999;
	margin-right: 0.5rem;
}

.create-questions-container .create-questions-navbar .questions {
	text-decoration: none;
	color: #fff;
}

.create-question-modal .error-text {
	color: rgb(255, 97, 97);
	font-size: 0.8rem;
}

.create-questions-container {
	display: flex;
	flex-direction: column;
	height: 100vh;
	background: linear-gradient(60deg, #000, #222, #000);
	animation: color 5s ease-in-out infinite;
	overflow: scroll;
}

.create-questions-container .loader-container {
	z-index: 100;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.9);
}

.loader {
	border: 4px solid #f3f3f3;
	border-top: 4px solid #edc500;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;
}

.create-questions-container .loader-container .loader-message {
	color: #aaa;
}

@keyframes color {
	0% {
		background-position: 0 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0 50%;
	}
}

.create-question-modal {
  height: 80vh;
  width: 50vw;
  padding: 2rem;
  background: linear-gradient(-135deg, rgba(0, 0, 0, 0.6), rgb(17, 17, 17, 0.6), rgba(0, 0, 0, 0.6));
  border-radius: 10px;
  flex-direction: column;
  backdrop-filter: blur(20px);
  border: 2px solid #222;
  box-shadow: 0 0 20px 5px #000;
}

.create-question-modal::backdrop {
	background: rgba(0, 0, 0, 0.6);
}

:modal {
	display: flex;
}

.create-question-modal .close-button {
  margin-left: auto;
  cursor: pointer;
}

.create-question-modal .progress-status {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.create-question-modal .progress-status .progress-bar {
  height: 4px;
  background-color: #333;
  flex-grow: 1;
  border-radius: 50vw;
}

.progress-status .progress-bar.done {
  background-color: #ccc;
}

 
.create-question-modal .question-form {
    margin-top: 2rem;
	display: flex;
    flex-wrap: wrap;
	gap: 0.75rem;
}

.question-form .form-group {
    position: relative;
	width: 48%;
	margin-top: 15px;
}

.question-form .form-group.image-select-group {
	width: 100%;
	height: fit-content;
} 

.question-form .form-group.image-select-group h4 {
	color: #fff;
}

.question-form .form-group .image-select {
	display: flex;
	gap: 1rem;
	height: 30%
}

.question-form .form-group.reference-answers {
	width: 100%;
}

.question-form .form-group input,
.question-form .form-group select,
.question-form .form-group textarea,
.question-form .actions select {
  box-sizing: border-box;
  width: 100%;
  padding: 0.75rem 1.25rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  color: #fff;
  font-family: 'Poppins';
  background: none;
  font-size: 14px;
  outline: none;
  background-color: transparent;
}

.question-form .form-group textarea {
	resize: vertical;
}

.question-form .form-group select {
  background: #000;
}

.question-form input:focus,
.question-form select:focus {
	border-color: #fff623;
	outline: none;
}

.question-form .form-group label {
	position: absolute;
	top: 25px;
	left: 20px;
	transform: translateY(-50%);
	font-size: 16px;
	color: #ccc;
	transition: 0.2s;
	pointer-events: none;
}

.form-group input:focus ~ label,
.form-group input:valid ~ label,
.form-group select:focus ~ label,
.form-group select:valid ~ label {
	top: 0;
	font-size: 12px;
	color: #ccc;
	background-color: #222;
}

.form-group .image-select .image-option {
	height: 100%;
	width: 15%;
	border-radius: 10px;
	padding: 0.3rem;
}

.form-group .image-select .image-option.selected {
	outline: 1px solid #dac100;
}

.question-form .actions {
	height: fit-content;
  width: 100%;
  flex-grow: 1;
  margin-top: 4rem;
  padding-bottom: 2rem;
  align-self: flex-end;
  display: flex;
  gap: 1rem;
}

.question-form .actions select {
	width: fit-content;
	background-color: #111;
}

.question-form .actions button.back {
  margin-left: auto;
}

/* Specificity added for create-questions main */
.create-questions-container .create-questions-main {
	flex: 1;
	padding: 20px;
	margin-inline: auto;
	width: 80%;
}

.create-questions-container .create-questions-main h1 {
	display: flex;
	/* align-items: center; */
	justify-content: center;
	font-size: 40px;
	color: white; /* Ensure text remains visible */
}

/* Specificity added for h2 tags */
.create-questions-container .create-questions-main h2 {
	color: white;
}


.questions-list-container {
	width: 80%;
	margin-inline: auto;
	padding: 1.5rem 3rem;
	margin-bottom: 15px;
	background-color: rgba(0, 0, 0, 0.4);
	border: 1px solid #222;
	border-radius: 5px;
	max-width: 1400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.questions-list-container .question-set-select {
	display: flex;
	gap: 1rem;
}

.questions-list-container .question-set-select .question-set-option {
	display: flex;
	justify-content: center;
	padding: 0.6rem;
	flex-grow: 1;
	border: 1px solid #444;
	border-radius: 6px;
	cursor: pointer;
}

.questions-list-container .question-set-select .question-set-option.selected {
	background-color: #222;
}

.questions-list {
  list-style-type: none;
  padding: 0;
}

.questions-list li {
  padding: 10px 0;
  border-bottom: 1px solid #666;
  display: flex;
  flex-direction: row;
}

.questions-list-container .questions-list .left {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.questions-list-container .questions-list .right {
  width: 30%;
}

.iframe-video {
  width: 100%;
  min-width: 200px;
  height: 450px;
  margin-top: 10px;
  border-radius: 4px;
  border: none;
}


.side-drawer {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.4);
	top: 0;
	right: 0;
	height: 100vh;
	width: 40vw;
	overflow-y: scroll;
	backdrop-filter: blur(20px);
	box-shadow: 0 0 100px 20px #000;
	transition: all 300ms ease-in;
}

.side-drawer.hide {
	right: -40vw;
	box-shadow: none;
}

.side-drawer .close-button {
	position: sticky;
	top: 1rem;
	left: 1rem;
	cursor: pointer;
	width: fit-content;
}

.generate-questions-form {
	width: 80%;
	margin-inline: auto;
	display: flex;
	flex-direction: column;
	height: fit-content;
}

.generate-questions-form form {
	width: 100%;
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;
}

.generate-questions-form form textarea,
.generate-questions-form form select {
	flex-grow: 1;
	padding: 0.75rem 1.2rem;
	background-color: #111;
	color: white;
	border: 1px solid #aaa;
	border-radius: 10px;
	resize: vertical;
	font-family: 'Poppins';
	font-size: 0.9rem;
	transition: outline 100ms ease-in;
}

.generate-questions-form form textarea:focus {
	outline: 1px solid #dac100;
}

.generate-questions-form form button {
	margin-left: auto;
	cursor: pointer;
	padding: 0.75rem 1.2rem;
	background-color: #dac100;
	border: none;
	font-weight: 600;
	border-radius: 10px;
	white-space: nowrap;
	transition: all 100ms ease-in;
}

.generate-questions-form form button:hover {
	background-color: #222;
	color: #dac100;
}

.response-questions {
	margin-block: 4rem;
	width: 80%;
	margin-inline: auto;
	display: flex;
	flex-wrap: wrap;
	gap: 0.85rem;
}	


.response-questions .question textarea,
.response-questions select {
	flex-grow: 1;
	field-sizing: content;
	padding: 0.8rem 1.2rem;
	background-color: #111;
	border: 1px solid #333;
	color: #ddd;
	font-size: 0.85rem;
	border-radius: 10px;
	font-family: 'Poppins';
	resize: none;
}

.response-questions select {
	width: 45%;
}

.response-questions .question texarea:active,
.response-questions .question texarea:focus {
	border: 1px solid #edc500;
	outline: 1px solid #edc500;
}

.response-questions .create-questions-from-response {
	margin-left: auto;
}

textarea {
	field-sizing: content;
}