.container {
  width: 98%;
  min-height: 100vh;
  margin: 0 auto;
  background: linear-gradient(60deg, #000, #222, #000);
  color: white;
  font-family: "Poppins", sans-serif;
}

.backLink {
  display: inline-flex;
  align-items: center;
  gap: 0.1rem;
  color: #edc500;
  text-decoration: none;
  margin-bottom: 2rem;
  transition: color 0.2s;
}

.backLink:hover {
  color: #ffe44d;
}

.content {
  width: 100%;
  /* margin: 0 auto; */
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 1rem;
  border: 1px solid rgba(237, 197, 0, 0.2);
  overflow: auto;
  margin-bottom: 10px;
}

.header {
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid rgba(237, 197, 0, 0.2);
}

.name {
  font-size: 2rem;
  color: #edc500;
  margin-bottom: 1rem;
}

.contactInfo {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  place-items: center;
  margin: 1rem;
  /*margin-right: 1rem;*/
}

.contactItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #cccccc;
  width: fit-content;
}

.section {
  margin-bottom: 2rem;
}

.sectionTitle {
  font-size: 1.5rem;
  color: #edc500;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.cvContent {
  box-sizing: border-box;
  display: grid;
  width: 95%;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 1rem;
}

.cvCard {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 2rem 2.2rem;
  border-radius: 8px;
  white-space: pre-wrap;
  box-sizing: border-box;
}

.cvSuccess {
  border-left: 4px solid rgba(134, 255, 100, 0.4);
}

.cvFail {
  border-left: 4px solid rgba(255, 98, 54, 0.4);
}

.cvCard.cvFail p,
.cvCard.cvSuccess p {
  color: #ddd;
}

.cvCard h3 {
  margin-block: 0;
  color: #ddd;
}

.cvCard p {
  color: #aaa;
  font-size: 0.9rem;
  margin-block: 0.2rem;
}

.actions {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 0 auto;
}

.actions button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.previewButton {
  background-color: rgba(237, 197, 0, 0.1);
  border: 1px solid #edc500;
  color: #edc500;
}

.previewButton:hover {
  background-color: rgba(237, 197, 0, 0.2);
}

.downloadButton {
  background-color: #edc500;
  border: 1px solid #edc500;
  color: #000000;
}

.downloadButton:hover {
  background-color: #ffe44d;
}

.loaderContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(60deg, #000, #222, #000);
  color: white;
}

.loader {
  border: 4px solid rgba(237, 197, 0, 0.1);
  border-top: 4px solid #edc500;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.errorContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(60deg, #000, #222, #000);
  color: white;
  gap: 1rem;
}

/* Tab styles */
.cvTabs {
    display: flex;
    margin: 0;
    background: #1a1a1a;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0;
    position: relative;
  }
  
  .cvTabButton {
    padding: 1rem 2rem;
    cursor: pointer;
    background: transparent;
    border: none;
    color: #888;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    position: relative;
    transition: all 0.3s ease;
  }
  
  .cvTabButton:not(:last-child) {
    border-right: 1px solid #333;
  }
  
  .cvTabButton:hover {
    color: #dac100;
  }
  
  .cvTabButton.active {
    color: #edc500;
    background: #222;
  }
  
  .cvTabButton.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #edc500;
  }
  
  .cvTabContent {
    min-height: 300px;
    background: #1d1d1d;
    margin: 0;
    padding: 0.5rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

/* Container styles */

/* Inner container styles */
.cvStatusBoxes {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap; /* Makes it responsive */
    margin-bottom: 1rem;
}

/* Individual box styling */
.cvStatusBox {
    background: #222; /* Better contrast */
    border: 2px solid rgba(255, 246, 35, 0.3); /* Yellow border with opacity */
    border-radius: 12px;
    padding: 0.7rem;
    flex: 1;
    min-width: 200px; /* Smaller minimum width */
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.outerBox{
    background: #131212;
}

/* Specific styles for Interview Scores section */

.interviewScores {
    display: flex;
    justify-content: space-between; /* Align left and right sections */
    gap: 1rem; /* Space between columns */
    align-items: stretch; /* Ensure equal height of both columns */
}

/* Group Total Score and Rank in a single container */
.leftColumn {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Space between Total Score and Rank */
    flex: 1; /* Equal width for left and right columns */
}

/* Percentile box styles */
.rightColumn {
    flex: 1; /* Equal width for left and right columns */
    width: 50%;
}

/* Individual sub-box styling */
.cvStatusSubBox {
    display: flex;
    background: #222; /* Better contrast */
    border: 2px solid rgba(255, 246, 35, 0.3); /* Yellow border with opacity */
    border-radius: 12px;
    padding: 0.7rem;
    /* flex: 1; */
    min-width: 200px; /*Smaller minimum width*/
    gap: 2rem;
    /* text-align: center; */
    transition: all 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

/* Header styles */
.cvStatusBox h3 {
    font-size: 0.85rem;
    /* margin-bottom: 0.8rem; */
    color: #fff623; /* Bright yellow */
    text-transform: uppercase;
    letter-spacing: 0.05rem;
}

/* Hover effect */
.cvStatusBox:hover, .cvStatusSubBox:hover {
    background: #333; /* Slightly lighter background */
    border-color: #fff623; /* Full yellow border */
    transform: translateY(-3px); /* Subtle lift */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

/* Sub-header styles */
.cvStatusSubBox h4 {
    font-size: 0.8rem;
    color: #ccc;
    margin-left: 0.5rem;
}

/* Paragraph (data) styles */
.cvStatusSubBox p {
    /* font-size: 1.2rem;
    font-weight: 700;
    color: #fff; */
    display: flex;
    /* align-items: start; */
}

.chart {
    margin-left: 0;
    margin-right: 160px; 
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .content {
    padding: 1rem;
  }

  .contactInfo {
    grid-template-columns: 1fr;
  }

  .actions {
    flex-direction: column;
  }

    .actions button {
        width: 100%;
        justify-content: center;
    }

    .cvStatusBoxes {
        flex-direction: column; /* Stack boxes vertically on smaller screens */
    }

    .interviewScores {
        grid-template-columns: 1fr; /* Single column on smaller screens */
    }

    .cvStatusBox {
        margin-bottom: 1rem;
    }
}
