.invite-container {
	min-height: 100vh;
	background: linear-gradient(60deg, #000, #222, #000);
	color: #fff;
	font-family: 'Inter', sans-serif;
	height: 100%;
}

.invite-header {
	background: rgba(0, 0, 0, 0.2);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	padding: 1.5rem 2rem;
}

.header-content {
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	gap: 2rem;
}

.title-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 2rem;
	position: relative;
}

.csv-upload-container {
	display: inline-flex;
	align-items: center;
	margin-left: auto;
}

.csv-upload-button {
	display: inline-block;
	padding: 0.5rem 1rem;
	background: #edc500;
	color: #000;
	border-radius: 4px;
	cursor: pointer;
	font-weight: 600;
	transition: background-color 0.3s;
	font-size: 0.9rem;
}

.csv-upload-button:hover {
	background: #ffd700;
}

.csv-upload-input {
	display: none;
}

.logo {
	text-decoration: none;
	font-size: 1.5rem;
	font-weight: bold;
}

.logo-peh {
	color: #fff;
	font-family: 'Poppins', sans-serif;
}

.logo-chaan {
	color: #edc500;
	font-family: 'Poppins', sans-serif;
}

.breadcrumb {
	color: #999;
	font-family: 'Poppins', sans-serif;
}

.breadcrumb a {
	color: #999;
	text-decoration: none;
	transition: color 0.3s;
}

.breadcrumb a:hover {
	color: #edc500;
}

.main-content {
	max-width: 1200px;
	margin: 2rem auto;
	padding: 2rem;
	background: rgba(255, 255, 255, 0.05);
	border-radius: 12px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.page-title {
	color: #edc500;
	font-size: 2rem;
	margin: 0;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.candidates-count {
	text-align: center;
	color: #999;
	margin-bottom: 2rem;
}

.invite-form {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.candidates-list {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	max-height: calc(5 * 80px);
	overflow-y: auto;
	padding-right: 1rem;
}

.candidates-list::-webkit-scrollbar {
	width: 8px;
}

.candidates-list::-webkit-scrollbar-track {
	background: rgba(255, 255, 255, 0.1);
	border-radius: 4px;
}

.candidates-list::-webkit-scrollbar-thumb {
	background: #edc500;
	border-radius: 4px;
}

.candidate-card {
	display: flex;
	align-items: center;
	background: rgba(255, 255, 255, 0.05);
	border-radius: 8px;
	padding: 1rem;
	border: 1px solid rgba(255, 255, 255, 0.1);
	gap: 1rem;
}

.candidate-number {
	width: 120px;
	flex-shrink: 0;
	color: #edc500;
	font-size: 1rem;
}

.input-container {
	display: flex;
	flex: 1;
	gap: 1rem;
	min-width: 0;
	flex-wrap: nowrap;
}

.input-group {
	flex: 1;
	min-width: 0;
	position: relative;
}

.input-field {
	width: 100%;
	padding: 0.75rem 1rem;
	background: rgba(255, 255, 255, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 4px;
	color: #fff;
	font-size: 0.9rem;
	transition: all 0.3s;
	box-sizing: border-box;
}

.input-field::placeholder {
	color: white !important;
	/* Ensures the placeholder color is always white */
	opacity: 1;
	/* Ensures the placeholder is fully visible */
}

.input-field:focus {
	outline: none;
	border-color: #edc500;
	background: rgba(255, 255, 255, 0.15);
}

.input-field.error {
	border-color: #ff4444;
}

.error-message {
	position: absolute;
	color: #ff4444;
	font-size: 0.75rem;
	bottom: -20px;
	left: 0;
}

.remove-button {
	background: none;
	border: none;
	color: #ff4444;
	font-size: 1.5rem;
	cursor: pointer;
	padding: 0;
	transition: color 0.3s;
	width: 30px;
	flex-shrink: 0;
}

.remove-button:hover {
	color: #ff0000;
}

.add-button {
	width: 100%;
	padding: 1rem;
	background: none;
	border: 2px dashed #edc500;
	color: #edc500;
	border-radius: 4px;
	cursor: pointer;
	font-size: 1rem;
	transition: all 0.3s;
}

.add-button:hover {
	background: rgba(237, 197, 0, 0.1);
}

.submit-button {
	width: 100%;
	padding: 1rem;
	background: #edc500;
	border: none;
	border-radius: 4px;
	color: #000;
	font-size: 1rem;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.3s;
}

.submit-button:hover {
	background: #ffd700;
}

.submit-button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.message {
	text-align: center;
	color: #edc500;
	margin-top: 1rem;
}

.loader-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 60vh;
}

.loader {
	border: 4px solid rgba(237, 197, 0, 0.1);
	border-top: 4px solid #edc500;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;
}

.loader-text {
	color: #edc500;
	margin-top: 1rem;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@media (max-width: 768px) {
	.main-content {
		margin: 1rem;
		padding: 1rem;
	}

	.header-content {
		flex-direction: column;
		gap: 1rem;
		align-items: flex-start;
	}

	.title-container {
		flex-direction: column;
		align-items: center;
		gap: 1.5rem;
		text-align: center;
		margin-bottom: 2rem;
	}

	.candidate-card {
		flex-direction: column;
		align-items: stretch;
		gap: 1.5rem;
	}

	.input-container {
		flex-direction: column;
		gap: 1rem;
	}

	.candidate-number {
		width: auto;
		margin-bottom: 0.5rem;
	}

	.remove-button {
		align-self: flex-end;
		margin-top: 0.5rem;
	}

	.page-title {
		position: static;
		transform: none;
		margin-bottom: 1rem;
	}

	.csv-upload-container {
			margin-left: 0;
			/* Reset margin for mobile */
	}

	.input-group {
		width: 100%;
	}

	.dropdown-menu {
		position: fixed;
		top: auto;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0;
		border-radius: 12px 12px 0 0;
		padding: 0.5rem 0;
	}

	.dropdown-item {
		padding: 1rem;
	}
}

.upload-buttons-container {
	display: flex;
	gap: 1rem;
	margin-left: auto;
}

.upload-button {
	display: inline-block;
	padding: 0.5rem 1rem;
	background: #edc500;
	color: #000;
	border-radius: 4px;
	cursor: pointer;
	font-weight: 600;
	transition: background-color 0.3s;
	font-size: 0.9rem;
}

.upload-button:hover {
	background: #ffd700;
}

.file-upload-input {
	display: none;
}

.pdf-section {
	background: rgba(255, 255, 255, 0.05);
	border-radius: 8px;
	padding: 1.5rem;
	margin-bottom: 2rem;
}

.section-title {
	color: #edc500;
	font-size: 1.2rem;
	margin: 0 0 1rem 0;
}

.pdf-list {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	max-height: 200px;
	overflow-y: auto;
	margin-bottom: 1rem;
}

.pdf-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: rgba(255, 255, 255, 0.1);
	padding: 0.75rem 1rem;
	border-radius: 4px;
}

.pdf-name {
	color: #fff;
	font-size: 0.9rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.remove-pdf-button {
	background: none;
	border: none;
	color: #ff4444;
	font-size: 1.2rem;
	cursor: pointer;
	padding: 0.25rem 0.5rem;
	transition: color 0.3s;
}

.remove-pdf-button:hover {
	color: #ff0000;
}

.process-pdfs-button {
	width: 100%;
	padding: 1rem;
	background: #edc500;
	border: none;
	border-radius: 4px;
	color: #000;
	font-size: 1rem;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.3s;
	margin-top: 1rem;
}

.process-pdfs-button:hover {
	background: #ffd700;
}

.process-pdfs-button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

@media (max-width: 768px) {
	.upload-buttons-container {
		flex-direction: column;
		width: 100%;
		gap: 0.5rem;
	}

	.upload-button {
		width: 100%;
		text-align: center;
	}

	.pdf-section {
		padding: 1rem;
	}
}

.tabs-container {
	margin-top: 2rem;
}

.tabs {
	display: flex;
	gap: 1rem;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	margin-bottom: 2rem;
}

.tab {
	padding: 1rem 2rem;
	background: none;
	border: none;
	color: #999;
	font-size: 1rem;
	cursor: pointer;
	transition: all 0.3s;
	position: relative;
}

.tab:hover {
	color: #edc500;
}

.tab.active {
	color: #edc500;
}

.tab.active::after {
	content: '';
	position: absolute;
	bottom: -1px;
	left: 0;
	width: 100%;
	height: 2px;
	background: #edc500;
}

.tab-content {
	min-height: 400px;
}

.dropzone {
	border: 2px dashed rgba(237, 197, 0, 0.3);
	border-radius: 8px;
	padding: 3rem 2rem;
	text-align: center;
	cursor: pointer;
	transition: all 0.3s;
	background: rgba(255, 255, 255, 0.02);
}

.dropzone:hover {
	border-color: #edc500;
	background: rgba(255, 255, 255, 0.05);
}

.dropzone-active {
	border-color: #edc500;
	background: rgba(237, 197, 0, 0.1);
}

.dropzone-content {
	color: #fff;
}

.dropzone-hint {
	color: #999;
	font-size: 0.9rem;
	margin-top: 0.5rem;
}

.cv-tab-content {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.csv-tab-content {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

@media (max-width: 768px) {
	.tabs {
		flex-direction: row;
		overflow-x: auto;
		padding-bottom: 1px;
	}

	.tab {
		padding: 0.75rem 1rem;
		white-space: nowrap;
	}

	.dropzone {
		padding: 2rem 1rem;
	}
}

.dropdown-container {
	position: relative;
	display: inline-block;
}

.csv-dropdown-button {
	padding: 0.5rem 1rem;
	background: #edc500;
	color: #000;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	font-weight: 600;
	transition: background-color 0.3s;
	font-size: 0.9rem;
}

.csv-dropdown-button:hover {
	background: #ffd700;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	right: 0;
	margin-top: 0.5rem;
	background: #1a1a1a;
	border: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 4px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
	z-index: 1000;
	min-width: 160px;
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.75rem 1rem;
	color: #fff;
	text-align: left;
	background: none;
	border: none;
	cursor: pointer;
	font-size: 0.9rem;
	transition: background-color 0.3s;
}

.dropdown-item:hover {
	background: rgba(237, 197, 0, 0.1);
	color: #edc500;
}

.dropdown-item:not(:last-child) {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}